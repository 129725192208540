export default [
  {
    id: '123123123',
    email: 'test@mail.ru',
    email_confirmed: 'test@mail.ru',
    phone: '+79999999999',
    first_name: 'Иван',
    last_name: 'Иванов',
    middle_name: 'Иванович',
  },
  {
    id: '123123123',
    email: 'test@mail.ru',
    email_confirmed: 'test@mail.ru',
    phone: '+79999999999',
    first_name: 'Иван',
    last_name: 'Иванов',
    middle_name: 'Иванович',
  },
  {
    id: '123123123',
    email: 'test@mail.ru',
    email_confirmed: 'test@mail.ru',
    phone: '+79999999999',
    first_name: 'Иван',
    last_name: 'Иванов',
    middle_name: 'Иванович',
  },
];
