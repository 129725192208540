<template>
  <div class="user-list">
    <br>
    <el-table
      :lazy="true"
      :data="tableData"
      style="width: 100%"
      height="75vh">
      <el-table-column
        label="id"
        prop="id" />
      <el-table-column
        label="Email"
        prop="email" />
      <el-table-column
        label="Телефон"
        prop="phone" />
      <el-table-column
        label="Имя"
        prop="first_name" />
      <el-table-column
        label="Фамилия"
        prop="last_name" />
      <el-table-column align="right">
        <template slot-scope="scopeData">
          <router-link
            :to="`/user/${scopeData.row.id}`">
            <el-button type="primary">
              Подробнее
              <i class="el-icon-arrow-right" />
            </el-button>
          </router-link>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import tableData from './data.js';

export default {
  name: 'UsersList',
  data() {
    return {
      tableData,
      isDisableSave: true,
      search: '',
    };
  },
  computed: {
    ...mapGetters(['landinglist']),
  },
  mounted() {
    this.setPageTitle('Пользователи');
  },
  methods: {
    ...mapMutations(['setPageTitle']),
    handleCurrentChange(val) {
      this.$router.push(`/user/${val.id}`);
    },
    createLink() {},
  },
};
</script>

<style lang="scss">
.user-list {
  .right-text {
    text-align: right;
  }
  .el-table__row {
    cursor: pointer;
  }
}
</style>
